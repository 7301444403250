<template>
  <v-container>
    <v-row>
      <v-col class="mx-auto" cols="12" lg="6" md="8" sm="8">
        <v-card flat>
          <v-card-title primary-title>
            <h4>Login</h4>
          </v-card-title>
          <v-form @submit.prevent="submit">
            <v-text-field v-model="form.username" prepend-icon="mdi-account" name="username"
                          label="Benutzername"></v-text-field>
            <v-text-field v-model="form.password" prepend-icon="mdi-lock" name="password" label="Passwort"
                          type="password"></v-text-field>
            <v-card-actions>
              <v-btn type="submit" primary large block>Login</v-btn>
            </v-card-actions>
          </v-form>
          <p v-if="showError" class="red--text" id="error">Benutzername oder Passwort ist falsch.</p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      showError: false
    };
  },
  methods: {
    ...mapActions(["LogIn"]),
    async submit() {
      try {
        await this.LogIn({'username': this.form.username, 'password': this.form.password});
        this.$router.push("/");
        this.showError = false
      } catch (error) {
        console.log(error)
        this.showError = true
      }
    }
  },
};
</script>